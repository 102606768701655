<template>
  <LayoutBox :loading='loading' title='预警信息处理一览'>
    <div
      style='
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 8px;
      '
    >
      <Tabs
        v-model='queryParam.handleFlag'
        :options='handlewarning'
        :replace-fields="{
          type: 'code',
          lable: 'text',
          value: 'count',
        }"
      ></Tabs>
      <div
        ref='scrollbarY'
        class='scrollbar-y'
        style='margin-top: 16px; padding-bottom: 24px'
        @scroll='onScroll'
      >
        <v-overlay :value='overlay' absolute>
          <v-progress-circular indeterminate size='64'></v-progress-circular>
          <div>数据提交中...</div>
        </v-overlay>
        <div
          v-for='(e, i) in dataSource'
          :key='i'
          style='
            padding: 16px 0;
            border-bottom: 1px solid #175192;
            display: flex;
            cursor: pointer;
          '
          @click="$refs.EarlyWarningHandleInfoDialog.openDialog({ row: e,type })"
        >
          <div
            style='
              width: 194px;
              height: 142px;
              margin-right: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
            '
          >
            <img
              v-if='
                !e.attachList || !e.attachList[0] || isImage(e.attachList[0])
              '
              v-lazy='`${$imgUrl}${e.attachList && e.attachList[0]}`'
              :alt='e.attachList[0]'
              style='
                flex: 1;
                object-fit: contain;
                overflow: hidden;
                height: 100%;
              '
            />
            <video
              v-else
              :src='`${$imgUrl}${e.attachList[0]}`'
              controls
              style='
                flex: 1;
                object-fit: contain;
                overflow: hidden;
                width: 100%;
                height: 100%;
              '
            ></video>
          </div>
          <div
            style='
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 14px;
              font-weight: 400;
            '
          >
            <span>{{ e.organizationText }}</span>
            <span>{{ e.warningTime }}</span>
            <div>
              <span
                v-if='e.analysisItemText'
                :style="`
                  border-right:${e.description ? '1px solid #1861b2' : 'none'};
                  padding-right: 8px;
                  margin-right: 8px;`"
              >{{ e.analysisItemText }}</span
              >
              <span>{{ e.description }}</span>
            </div>
            <div
              v-if="queryParam.handleFlag != '01'"
              :style='`color:${
                reviewColor[e.handleFlag]
              };font-size: 16px;font-weight: 400;`'
            >
              {{ e.handleFlagText }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <EarlyWarningHandleInfoDialog
      ref='EarlyWarningHandleInfoDialog'
      :prefix='prefix'
      @success="$emit('ok')"
    ></EarlyWarningHandleInfoDialog>
  </LayoutBox>
</template>

<script>
import { isImage, debounce } from '@/views/screen/config';
import EarlyWarningHandleInfoDialog from '@/views/screen/earlyWarning/modules/EarlyWarningHandleInfoDialog.vue';

export default {
  //组件注册
  components: {
    LayoutBox: () => import('@/views/screen/components/LayoutBox.vue'),
    Tabs: () => import('@/views/screen/cashSupervision/components/Tabs.vue'),
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'staff',
    },
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      rowId: 'uuid',
      reviewColor: {
        '02': '#2CB7FF',
        '03': '#FFCC1B',
      },
      overlay: false,
      loading: false,
      handlewarning: [],
      queryParam: {},
      dataSource: [],
      ipagination: {
        pageNo: 1,
        maxResults: 10,
        pages: 0,
        visible: 7,
        total: 0,
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: 'refresh',
    },
    globalQuery: {
      deep: true,
      handler: function() {
        this.getHandlewarning();
      },
    },
    refreshKey: {
      handler: function() {
        this.getHandlewarning(true);
      },
    },
  },
  //DOM访问
  mounted() {
    this.getHandlewarning(true);
    this.$bus.$on(`warning-success-${this.prefix}`, this.getHandlewarning);
  },
  //保存方法
  methods: {
    isImage,
    refresh() {
      this.ipagination.pageNo = 1;
      this.getData();
      this.$nextTick(() => {
        this.$refs.scrollbarY.scrollTop = 0;
      });
    },
    async getHandlewarning(isRefresh = false) {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/${this.prefix}/handlewarning-stat`,
        this.globalQuery,
      );
      if (data.code == 200 && data.data) {
        this.handlewarning = data.data;
        if (isRefresh) {
          this.queryParam = {
            handleFlag: '01',
            ...this.queryParam,
          };
        } else {
          this.getData();
        }
      }
    },
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/${this.prefix}/${this.prefix}-page`,
          {
            pageNo: this.ipagination.pageNo,
            maxResults: this.ipagination.maxResults,
            status: '02',
            ...this.globalQuery,
            ...this.queryParam,
          },
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        const result = data.data[0];
        if (this.ipagination.pageNo == 1) {
          this.dataSource = result.records;
        } else {
          this.onExcludeRow(result.records);
        }
        if (this.ipagination.pageNo != 1 && !result.records.length) {
          this.ipagination.pageNo -= 1;
        }
        this.ipagination.pages = result.pages;
        this.ipagination.total = result.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    onScroll: debounce(function(e) {
      // 获取距离顶部的距离
      const scrollTop = e.target.scrollTop;
      // 获取可视区的高度
      const windowHeight = e.target.clientHeight;
      // 获取滚动条的总高度
      const scrollHeight = e.target.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        this.ipagination.pageNo += 1;
        this.getData();
      }
    }, 250),
    onExcludeRow(records) {
      for (const e of records) {
        if (!this.dataSource.find((ee) => ee[this.rowId] == e[this.rowId]))
          this.dataSource.push(e);
      }
    },
  },
};
</script>

<style lang='scss' scoped></style>
